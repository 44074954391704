<script setup lang="ts">
  import { MpBox } from '@borg/ui';
  import { normalizeNumericValue } from '@borg/utils';

  const layout = useLayout();
  const { y: scrollPositionY } = useWindowScroll();

  const showPendingJobApplication = computed(
    () =>
      !isCurrentPathAnyOf(
        '/job/:jobId/:slug/apply',
        '/auth',
        '/auth/login',
        '/auth/forgotten-password',
        '/my-profile-preview',
        '/application/verification',
      ),
  );
  const showGtmMessage = computed(
    () =>
      !isCurrentPathAnyOf(
        '/auth',
        '/auth/login',
        '/auth/forgotten-password',
        '/my-profile-preview',
      ),
  );
  const showHero = computed(() => isCurrentPath('/'));
  const hasBorder = computed(() => !(isCurrentPath('/') || isCurrentPath('/search')));
  const shadowVisibilityFactor = computed(() =>
    normalizeNumericValue(scrollPositionY.value, 30, 60),
  );
  const shadowBlur = computed(() => `${shadowVisibilityFactor.value}rem`);
  const shadowY = computed(
    () => `${normalizeNumericValue(shadowVisibilityFactor.value, 0, 1, 0, 0.5)}rem`,
  );

  const almaFooterContent = ref('');

  onBeforeMount(async () => {
    const url = '/content/alma-footer';
    try {
      const response = await apiFetch(url);
      const html = response;
      almaFooterContent.value = html;
    } catch (error) {
      logger.error(`Error fetching Alma Footer: ${error}`);
    }
  });
</script>

<template>
  <MpBox
    class="page"
    surface="50"
  >
    <MpBox
      v-if="layout.showHeader"
      as="header"
      surface="50"
      :class="{
        'page__header': true,
        'page__header--bordered': hasBorder,
      }"
    >
      <SiteHeader />
    </MpBox>
    <PendingJobApplication v-if="showPendingJobApplication" />
    <div
      v-if="showGtmMessage"
      id="gtm-message-target"
    />
    <SiteHero v-if="showHero" />
    <div class="page__content">
      <slot />
    </div>
    <footer
      v-if="layout.showFooter"
      class="page__footer"
    >
      <div class="page__footer__top">
        <SiteFooter />
      </div>
      <div
        v-if="almaFooterContent.length"
        v-html="almaFooterContent"
      />
    </footer>
    
  </MpBox>
</template>

<style scoped lang="scss">
  .page {
    position: relative;

    &__header {
      position: sticky;
      top: 0;
      left: 0;
      z-index: 8;
      border-bottom: 1px solid transparent;
      box-shadow: 0 v-bind(shadowY) v-bind(shadowBlur) hsla(0 0% 0% / 0.08);
      color: var(--site-header-text-color);
      background-color: var(--site-header-background-color);
      transition:
        color 150ms,
        background-color 150ms;

      & > * {
        margin: 0 auto;
        max-width: var(--site-content-max-width);
        padding: 0 var(--site-content-horizontal-padding);
      }

      &--bordered {
        border-bottom-color: var(--mp-color-border-c-10);
      }
    }

    &__content {
      max-width: var(--site-content-max-width);
      margin: 0 auto;
      padding-top: var(--site-content-top-padding);
      padding-left: var(--site-content-horizontal-padding);
      padding-right: var(--site-content-horizontal-padding);
      padding-bottom: var(--site-content-top-padding);
      min-height: 70vh;
    }

    &__footer {
      display: none;
      border-top: 1px solid var(--mp-color-border-c-10);

      @include screen-lg {
        display: block;
      }

      .page__footer__top {
        max-width: var(--site-content-max-width);
        margin: 0 auto;
        padding: var(--mp-space-90) var(--site-content-horizontal-padding);
      }
    }
  }
</style>
