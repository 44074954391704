<script setup lang="ts">
  import { MpDivider, MpIcon, MpIconButton, MpMenu } from '@borg/ui';

  const sidebar = leftSidebar.provide();
</script>

<template>
  <FlyInMenu
    v-model="sidebar.isOpen"
    position="left"
    test="left-sidebar-menu-container"
    trigger-test="left-sidebar-trigger-button"
  >
    <template #trigger>
      <span class="site-menu__burger-container">
        <MpIcon
          class="site-menu__burger-icon"
          icon="menu"
          size="lg"
        />
      </span>
    </template>
    <header class="site-menu__header">
      <MpIconButton
        data-test="left-sidebar-close-button"
        icon="close"
        variant="plain"
        color-scheme="neutral"
        @click="sidebar.toggle"
      />
      <Logo />
    </header>
    <main class="site-menu__main">
      <MobileLinks />
      <MpDivider margin="sm" />
      <InfoLinksProvider>
        <template #default="{ links }">
          <MpMenu>
            <LinksLocale
              v-for="link in links"
              :key="link.to.toString()"
              :data-test="link.label"
              :to="link.to"
              @click="sidebar.toggle"
            >
              {{ link.label }}
            </LinksLocale>
          </MpMenu>
        </template>
      </InfoLinksProvider>
      <MpDivider margin="sm" />
      <div class="site-menu__wrapper">
        <LanguageSwitch />
      </div>
    </main>
  </FlyInMenu>
</template>

<style scoped lang="scss">
  .site-menu {
    &__burger-container {
      display: flex;

      @include screen-lg {
        display: none;
      }
    }

    &__burger-icon {
      cursor: pointer;
    }

    &__header {
      display: flex;
      align-items: center;
      gap: var(--mp-space-30);
      padding: var(--mp-space-60) var(--mp-space-50);
    }

    &__wrapper {
      padding: var(--mp-space-20) var(--mp-space-40);
    }
  }
</style>
